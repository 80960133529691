import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Article from "../components/article"
import Aside from "../components/aside"
import Item from "../components/item"
import Top from "../components/top"
import SEO from "../components/seo"

export default ({pageContext: { itemData, prevItem, nextItem, classId, className, randData}}) => (
    <Layout>
        <SEO title={itemData.title} />
        <Header nav={`class_${classId}`} />
        <div style={{
                display: `flex`,
                justifyContent: `space-around`,
                maxWidth: `960px`,
                margin: `1rem auto`
        }}>
            <Article obj={itemData} prevItem={prevItem} nextItem={nextItem} classId={classId} className={className} />
            <Aside>
                <Top />
                <Item obj={{
                    class_name: "谁便看看",
                    data: randData}} />
            </Aside>
        </div>
    </Layout>
)
