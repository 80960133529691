import React from "react"
import {Link} from "gatsby"
import Address from "./address"
import Axios from "axios"
import Style from "../styles/article.module.css"

class Article extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        Axios({
            method: "POST",
            url: "https://api.like-gif.com",
            data: {
                action: 2,
                obj: this.props.obj.id,
                app: 5
            },
            transformRequest: [function(data) {
                let ret = ''
                for(let it in data) {
                    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
                }
                return ret
            }],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    }

    render() {
        return (
            <section className={Style.box}>
                <Address classUrl={`/class/${this.props.classId}`} className={this.props.className}  />
                <article>
                    <h1>{this.props.obj.title}</h1>
                    <div className={Style.content} dangerouslySetInnerHTML={{ __html: this.props.obj.content }} />
                    <div style={{
                        paddingTop: `1rem`,
                        marginTop: `2rem`,
                        borderTop: `1px solid #eee`
                    }}>
                        <p>上一篇：{this.props.prevItem !== {}  ? <Link style={{color: `#007fff`}} to={`/story/${this.props.prevItem.id}`}>{this.props.prevItem.title}</Link> : "没有了"}</p>
                        <p>下一篇：{this.props.nextItem !== {} ? <Link style={{color: `#007fff`}} to={`/story/${this.props.nextItem.id}`}>{this.props.nextItem.title}</Link> : "没有了"}</p>
                    </div>
                </article>
            </section>
        )
    }
}

export default Article