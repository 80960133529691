import React from "react"
import Item from "../components/item"
import JsonData from "../../content/top.json"

let data = {
    class_name: "最多人看",
    data: JsonData
}

export default () => (
    <Item obj={data} />
)